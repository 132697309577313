<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="Document Category" slot="title" link="/helpContent/Document Category Detail" />
        <ValidationObserver ref="validator">
            <FormRow>
                <div class="col-sm-2">
                    <FormSelect label="Document Class" rules="required" :items="documentclassList" item-name="DocumentClass" item-value="id" v-model="form.DocumentClass"/>
                </div>
                <div class="col-sm-4">
                    <InputText label="Name" v-model="form.CategoryName" rules="required" />
                </div>
            </FormRow>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6" >
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="sendData()">Save</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template>
<script>
    import FormWrapper from "Components/form/FormWrapper";
    import PageTitle from "Components/layout/PageTitle";
    import loadingMixin from "Mixins/loadingMixin";
    import {getDocumentClass, getDocumentCategoryList, addUpdateDocumentCategory} from "../api";
    import {ValidationObserver} from "vee-validate"

    export default {
        name: "add",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle,
            ValidationObserver,
        },
        data() {
            return {
                showMessage: {
                    isVisible: false,
                },
                infoMessage: {
                    isVisible: false,
                },
                documentclassList: [],
                form: {
                    DocumentClass:null,
                    CategoryName:'',
                },
            };
        },
        computed: {
            userData() {
                return this.$store.state.user.user;
            },
        },
        created() {
            this.getAllList()
        },
        methods: {
            async  getAllList(){
                await getDocumentClass().then((res)=>{
                    this.documentclassList=res.data
                });
                this.getDataByFilter();
            },
            getDataByFilter() {
                if(this.$route.params.id){
                    setTimeout(() => {
                        // this.showLoader();
                        const filters = {};
                        filters.documentCategoryId = atob(this.$route.params.id);
                        const data = {
                            filterjson: {
                                filter: [filters]
                            }
                        };
                        getDocumentCategoryList(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
                }
            },
            sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            documentCategoryId: this.$route.params.id?atob(this.$route.params.id):0,
                            categoryName: this.form.CategoryName,
                            documentClass: this.form.DocumentClass,
                        };
                        addUpdateDocumentCategory(data).then((res)=>{
                            this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.DocumentClass = response.data[0].DocumentClass;
                this.form.CategoryName = response.data[0].DocumentCategory;
            }
        },
    };
</script>
<style lang="scss">
    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
</style>